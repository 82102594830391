.App {
  text-align: center;
  display: flex;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
}

form  {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

label {
  text-align: left;
}

input {
  width: 150px;
}
